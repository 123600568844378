import { ApiHandler } from "@/lib/ApiHandler";

class AuthService extends ApiHandler {
  constructor() {
    super("/api/auth");
  }

  async signIn(body) {
    return await this.post("authenticate", body)
      .then(res => res.json())
      .then(res => res.result);
  }

  async forgotPassword(body) {
    return await this.post("forgot-password", body)
      .then(res => res.json())
      .then(res => res.result);
  }

  async resetPassword(body) {
    return await this.post("reset-password", body)
      .then(res => res.json())
      .then(res => res.result);
  }
}

export const authService = new AuthService();
