<template>
  <div class="forgot-password-page">
    <v-card>
      <v-form class="forgot-password-page__form pa-12 text-left" @submit="sendMail">
        <div class="forgot-password-page__logo-container">
          <Logo/>
        </div>
        <v-text-field class="forgot-password-page__input-email" label="Email" type="email" v-model="credentials.email" append-icon="mdi-account" />
        <h6 class="forgot-password-page__error">{{ error }}</h6>
        <h6 class="forgot-password-page__success">{{ message }}</h6>
        <div class="text-center ma-6">
          <v-btn
            outlined
            color="primary"
            type="submit"
          >
            Send mail
          </v-btn>
        </div>

        <v-divider />

        <div class="mt-4">
          <router-link to="login">
            <p class="forgot-password-page__recovery-link">torna alla login</p>
          </router-link>
        </div>
      </v-form>
    </v-card>
    <Loading v-if="isLoading"/>
  </div>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import { authService } from "@/services/authService";
import Logo from "@/components/logo";
import Loading from "@/components/loading";


@Component({
  components:{
    Logo,
    Loading
  }
})

export default class ForgotPasswordPage extends Vue {
  error = "";
  message = "";
  isLoading = false;
  credentials = {
    email: ""
  };

  async sendMail ($event) {
    $event.preventDefault();
    if (!this.credentials.email) {
      this.error = "Email cannot be empty";
      return;
    }
    this.error = "";
    this.isLoading = true;
    try {
      await authService.forgotPassword({
        email: this.credentials.email
      });
      this.isLoading = false;
      let msg = "Una mail è stata inviata all'indirizzo inserito. Seguire le istruzioni in essa per recuperare la password";
        this.handleErrorSuccessMessage(msg, "success");


    } catch (error) {
        this.isLoading = false;
        this.handleErrorSuccessMessage(error.description, "fail");
    }

	}

  handleErrorSuccessMessage(msg, status){
    if(status === "success"){
      this.message = msg;

    setTimeout(()=>{
      this.message = "";
      }, 10000);
    }
    if(status === "fail"){
      this.error = msg;

    setTimeout(()=>{
      this.error = "";
      }, 4000);
    }

  }
}
</script>

<style lang="scss" scoped>
.forgot-password-page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("/bg_sky.jpg"), linear-gradient(145deg, #e6e6e6, #ffffff), #EBEEF7;

  &__logo-container{
    width: 320px;
    margin: 0 auto 25px;
  }

  &__error {
    color: #D63B01;
  }

  &__recovery-link{
    color: #4C4C4C;
    font-size: 13px;
  }

  &__success {
    color: #8BD499;
  }
}
</style>
